// components/SettingsPage/ExportSettings.tsx
import React, { useEffect, useState } from 'react';
import { Loader2, Save } from 'lucide-react';
import { databases } from '../../utils/appwrite';

interface ExportSettingsProps {
  onError: (message: string) => void;
  onSuccess: (message: string) => void;
}

export const ExportSettings: React.FC<ExportSettingsProps> = ({
  onError,
  onSuccess,
}) => {
  const [documentTitle, setDocumentTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [workshopId, setWorkshopId] = useState<string | null>(null);

  useEffect(() => {
    fetchWorkshopData();
  }, []);

  const fetchWorkshopData = async () => {
    try {
      setLoading(true);
      const response = await databases.listDocuments(
        '67197d230036564bfc99',
        'workshop'
      );

      if (response.documents.length > 0) {
        const doc = response.documents[0];
        setWorkshopId(doc.$id);
        setDocumentTitle(doc.documentTitle || 'Inspektionsprotokoll');
      }
    } catch (err) {
      console.error('Error fetching workshop data:', err);
      onError('Fehler beim Laden der Daten');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!workshopId) return;

    try {
      setSaving(true);
      await databases.updateDocument(
        '67197d230036564bfc99',
        'workshop',
        workshopId,
        {
          documentTitle: documentTitle.trim() || 'Inspektionsprotokoll'
        }
      );
      onSuccess('Dokumenttitel erfolgreich gespeichert');
    } catch (err) {
      console.error('Error saving document title:', err);
      onError('Fehler beim Speichern des Dokumenttitels');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-1">
          PDF Export Einstellungen
        </h3>
        <p className="text-sm text-gray-500">
          Hier können Sie den Standardtitel für exportierte Dokumente festlegen.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="documentTitle" className="block text-sm font-medium text-gray-700">
            Titel
          </label>
          <input
            type="text"
            id="documentTitle"
            value={documentTitle}
            onChange={(e) => setDocumentTitle(e.target.value)}
            maxLength={200}
            className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            placeholder="z.B. Inspektionsprotokoll"
          />
          <p className="mt-1 text-sm text-gray-500">
            {documentTitle.length}/200 Zeichen
          </p>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={saving}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {saving ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Speichern...
              </>
            ) : (
              <>
                <Save className="w-4 h-4 mr-2" />
                Speichern
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
