import React, { useRef, useCallback } from 'react';
import { Search, X, RotateCcw } from 'lucide-react';
import { ProcessType } from '../../types/process';
import { useDebounce } from '../../hooks/useDebounce';
import { useProcessStore } from '../../stores/processStore';

const STATUS_OPTIONS = [
  { value: 'all', label: 'Alle Status' },
  { value: '1', label: 'Neu' },
  { value: '2', label: 'Wird bearbeitet' },
  { value: '3', label: 'Wird geprüft' },
  { value: '4', label: 'Versendet' },
  { value: '5', label: 'Abgeschlossen' },
];

const TYPE_OPTIONS: { value: ProcessType | 'all'; label: string }[] = [
  { value: 'all', label: 'Alle Typen' },
  { value: 'empty', label: 'Leerer Vorgang' },
  { value: 'small-inspection', label: 'Kleine Inspektion' },
  { value: 'large-inspection', label: 'Große Inspektion' },
  { value: 'damage-report', label: 'Schadensgutachten' },
  { value: 'maintenance-report', label: 'Wartungsgutachten' },
];

const initialFilters = {
  search: '',
  status: 'all',
  type: 'all' as const,
};

export const FilterBar = React.memo(() => {
  const { filters, pagination, setFilters } = useProcessStore();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [localSearch, setLocalSearch] = React.useState(filters.search || '');
  const debouncedSearch = useDebounce(localSearch, 300);

  React.useEffect(() => {
    if (debouncedSearch !== filters.search) {
      setFilters({ ...filters, search: debouncedSearch });
    }
  }, [debouncedSearch, filters, setFilters]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalSearch(e.target.value);
    },
    []
  );

  const handleClearSearch = useCallback(() => {
    setLocalSearch('');
    searchInputRef.current?.focus();
  }, []);

  const handleResetAllFilters = useCallback(() => {
    setLocalSearch('');
    setFilters(initialFilters);
  }, [setFilters]);

  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value !== filters.status) {
        setFilters({ ...filters, status: e.target.value });
      }
    },
    [filters, setFilters]
  );

  const handleTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (e.target.value !== filters.type) {
        setFilters({
          ...filters,
          type: e.target.value as ProcessType | 'all',
        });
      }
    },
    [filters, setFilters]
  );

  const hasActiveFilters =
    filters.search || filters.status !== 'all' || filters.type !== 'all';

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="flex flex-col sm:flex-row items-stretch sm:items-center justify-between gap-2 h-16 px-4">
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 flex-grow">
          {/* Search input - visible on both mobile and desktop */}
          <div className="relative w-full sm:w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
            <input
              ref={searchInputRef}
              type="text"
              value={localSearch}
              onChange={handleSearchChange}
              placeholder="Suchen..."
              className="w-full pl-9 pr-9 py-2 text-sm bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            {localSearch && (
              <button
                onClick={handleClearSearch}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100"
              >
                <X className="w-4 h-4" />
              </button>
            )}
          </div>

          {/* Status and Type selectors - only visible on desktop */}
          <div className="hidden sm:flex flex-row gap-4">
            <select
              value={filters.status || 'all'}
              onChange={handleStatusChange}
              className="w-40 px-3 py-2 text-sm bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              {STATUS_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <select
              value={filters.type || 'all'}
              onChange={handleTypeChange}
              className="w-40 px-3 py-2 text-sm bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              {TYPE_OPTIONS.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Total count and reset button - only visible on desktop */}
        <div className="hidden sm:flex items-center gap-4">
          <span className="text-sm text-gray-600 whitespace-nowrap">
            <span className="font-medium text-gray-900">
              {pagination.total}
            </span>{' '}
            {pagination.total === 1 ? 'Vorgang' : 'Vorgänge'}
          </span>

          {hasActiveFilters && (
            <button
              onClick={handleResetAllFilters}
              className="flex items-center justify-center px-3 py-2 text-sm text-gray-600 hover:text-gray-800 bg-gray-50 hover:bg-gray-100 border border-gray-200 rounded-lg transition-colors duration-200"
            >
              <RotateCcw className="w-4 h-4 mr-2" />
              Filter zurücksetzen
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

FilterBar.displayName = 'FilterBar';
