import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Account } from 'appwrite';
import { client } from '../utils/appwrite';
import LoadingScreen from '../components/LoadingScreen';
import ErrorPage from '../components/ErrorPage';

const account = new Account(client);

export default function MagicLinkVerification() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = searchParams.get('userId');
    const secret = searchParams.get('secret');

    if (!userId || !secret) {
      setError('Invalid magic link parameters');
      return;
    }

    const verifyMagicLink = async () => {
      try {
        // Update session using magic link
        await account.updateMagicURLSession(userId, secret);
        navigate('/dashboard');
      } catch (err) {
        console.error('Magic link verification failed:', err);
        setError('Failed to verify magic link. Please try again.');
      }
    };

    verifyMagicLink();
  }, [searchParams, navigate]);

  if (error) {
    return <ErrorPage />;
  }

  return <LoadingScreen />;
}
