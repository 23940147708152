import { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { databases } from '../utils/appwrite';
import { Process } from '../types/process';
import { Sidebar } from '../components/Sidebar';
import { ProcessList } from '../components/ProcessList';
import { CreateProcessForm } from '../components/CreateProcessForm';
import { ProcessDetail } from '../components/ProcessDetail/ProcessDetail';
import { WorkshopForm } from '../components/WorkshopForm';
import TeamManagement from '../components/TeamManagement';
import SettingsPage from '../components/SettingsPage/SettingsPage';
import { FilterBar } from '../components/ProcessList/FilterBar';
import HelpPage from '../components/HelpPage';

export type ViewType =
  | 'list'
  | 'create'
  | 'detail'
  | 'workshop'
  | 'team'
  | 'settings'
  | 'help';

interface DashboardProps {
  defaultTab?: ViewType;
}

export default function Dashboard({ defaultTab }: DashboardProps): JSX.Element {
  const { name, logout, teamRole } = useAuth();
  const [processes, setProcesses] = useState<Process[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeView, setActiveView] = useState<ViewType>('list');
  const [selectedProcess, setSelectedProcess] = useState<Process | null>(null);

  useEffect(() => {
    if (defaultTab) {
      setActiveView(defaultTab);
    }
  }, [defaultTab]);

  useEffect(() => {
    const fetchProcesses = async () => {
      try {
        const response = await databases.listDocuments(
          '67197d230036564bfc99',
          '67197d2b003aece09c07'
        );
        const processData = response.documents.map((doc) => ({
          $id: doc.$id,
          serialnumber: doc.serialnumber,
          type: doc.type,
          status: doc.status,
          damage: doc.damage,
          quoteFileId: doc.quoteFileId,
          quoteName: doc.quoteName,
          created: doc.$createdAt,
          bikeId: doc.bikeId,
        }));
        setProcesses(processData);
        setError(null);
      } catch (err) {
        console.error('Error fetching processes:', err);
        setError(
          err instanceof Error ? err.message : 'Failed to load processes'
        );
      } finally {
        setLoading(false);
      }
    };
    fetchProcesses();
  }, []);

  const handleCreateSuccess = (newProcess: Process) => {
    setProcesses((prev) => [...prev, newProcess]);
    setSelectedProcess(newProcess);
    setActiveView('detail');
    setError(null);
  };

  const handleProcessClick = (process: Process) => {
    //  console.log('Dashboard: Process selected:', process);
    setSelectedProcess(process);
    setActiveView('detail');
    // console.log('Dashboard: Active view set to detail');
  };

  const handleViewChange = (view: ViewType) => {
    //   console.log('Dashboard: View change requested to:', view);
    setActiveView(view);
    if (view === 'list') {
      setSelectedProcess(null);
    }
    setError(null);
    // console.log('Dashboard: Active view set to:', view);
  };

  return (
    <div className="h-screen flex overflow-hidden">
      <Sidebar
        username={name ? name : 'Loading...'}
        teamRole={teamRole ? teamRole : 'Loading...'}
        onViewChange={handleViewChange}
        activeView={activeView}
        onSignOut={logout}
        onBack={() => handleViewChange('list')}
      />
      <div className="flex-1 overflow-auto">

        {/* Main content area */}
        <div className="p-0">
          {error && (
            <div className="text-red-600 bg-red-50 p-4 rounded-md mb-4">
              {error}
            </div>
          )}
          {activeView === 'settings' ? (
            <SettingsPage />
          ) : activeView === 'team' ? (
            <TeamManagement />
          ) : activeView === 'workshop' ? (
            <WorkshopForm />
          ) : activeView === 'help' ? (
            <HelpPage />
          ) : activeView === 'create' ? (
            <CreateProcessForm
              onCancel={() => handleViewChange('list')}
              onSuccess={handleCreateSuccess}
              onError={setError}
            />
          ) : activeView === 'detail' && selectedProcess ? (
            <ProcessDetail onBack={() => handleViewChange('list')} />
          ) : (
            <>
              {processes.length !== 0 && (
                <div className="md:block md:bg-white md:rounded-lg md:shadow mt-0">
                  <FilterBar />
                </div>
              )}
              <ProcessList
                onViewChange={handleViewChange}
                onProcessSelect={handleProcessClick}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
