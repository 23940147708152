import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { client } from './utils/appwrite';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import VerifyEmail from './pages/VerifyEmail';
import Logout from './pages/Logout';
import ErrorPage from './components/ErrorPage';
import { useEffect } from 'react';
import SecureVideoViewer from './pages/SecureVideoViewer';
import MagicLinkVerification from './pages/MagicLinkVerification';
import ResetPassword from './pages/ResetPassword';

/**
 * Hook to prevent PWA from closing on mobile back button press
 * @returns void
 */
const usePreventAppClose = (): void => {
  useEffect(() => {
    const handleBackButton = (e: PopStateEvent): boolean => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };
    // Initial state
    window.history.pushState(null, '', window.location.href);
    // Handle back button press
    window.addEventListener('popstate', handleBackButton);
    // Additional handler for initial load
    window.addEventListener('load', () => {
      window.history.pushState(null, '', window.location.href);
    });
    // Cleanup
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
};

interface AppProps {
  children?: React.ReactNode;
}

export default function App({ children }: AppProps): JSX.Element {
  usePreventAppClose();

  return (
    <Router>
      {/* First handle the public video route */}
      <Routes>
        <Route path="/v/:uuid" element={<SecureVideoViewer />} />
        <Route path="/verify-magic-link" element={<MagicLinkVerification />} />
        {/* Then wrap the authenticated routes in AuthProvider */}
        <Route path="*" element={
          <AuthProvider client={client}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/dashboard/*"
                element={
                  <ProtectedRoute>
                    <Routes>
                      <Route index element={<Dashboard />} />
                      <Route
                        path="settings"
                        element={<Dashboard defaultTab="settings" />}
                      />
                    </Routes>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Navigate to="/dashboard" replace />
                  </ProtectedRoute>
                }
              />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route
                path="/logout"
                element={
                  <ProtectedRoute>
                    <Logout />
                  </ProtectedRoute>
                }
              />
              <Route path="/unauthorized" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </AuthProvider>
        } />
      </Routes>
    </Router>
  );
}
