import { useState, FormEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { SignUpForm } from './SignUpForm';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isMagicLinkLoading, setIsMagicLinkLoading] = useState(false);
  const [showMagicLinkSuccess, setShowMagicLinkSuccess] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const { login, sendMagicLink, error: authError, setError } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(email, password);
      const from = (location.state as any)?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Login error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMagicLink = async (e: FormEvent) => {
    e.preventDefault();
    setIsMagicLinkLoading(true);
    setError('');

    try {
      await sendMagicLink(email);
      setShowMagicLinkSuccess(true);
    } catch (error) {
      setError('Fehler beim Senden des Magic Links');
    } finally {
      setIsMagicLinkLoading(false);
    }
  };

  const handleSignUpError = (error: string) => {
    setError(error);
  };

  const showMagicLinkOption = email === 'demo@bikecheck-pro.de';

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/logo.png" alt="Bike Check" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-700">
          BikeCheck <span className="text-gray-800">Pro</span>
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-sm sm:rounded-lg sm:px-10 border border-gray-200 mx-2 md:mx-0">
          {showSignUp ? (
            <SignUpForm
              onCancel={() => {
                setShowSignUp(false);
                setError('');
              }}
              onError={handleSignUpError}
              isLoading={isLoading}
            />
          ) : (
            <>
              <form className="space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Passwort
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 px-3"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="text-sm text-right mt-1">
                    <a href="/reset-password" className="text-blue-600 hover:text-blue-500">
                      Passwort vergessen?
                    </a>
                  </div>
                </div>

                {authError && (
                  <div className="rounded-md bg-red-50 p-4">
                    <div className="text-sm text-red-700">{authError}</div>
                    {authError.includes("when a session is active") && (
                      <div className="mt-2 text-right space-x-4">
                        <a
                          href="/dashboard"
                          className="text-xs text-gray-600 hover:text-gray-800 transition-colors"
                        >
                          Zum Dashboard
                        </a>
                        <a
                          href="/logout"
                          className="text-xs text-gray-600 hover:text-gray-800 transition-colors"
                        >
                          Abmelden
                        </a>
                      </div>
                    )}
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoading ? 'Geht los...' : 'Einloggen'}
                  </button>
                </div>
              </form>

              {showMagicLinkOption && (
                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="bg-white px-2 text-gray-500">Oder</span>
                    </div>
                  </div>

                  <div className="mt-6">
                    <button
                      onClick={handleMagicLink}
                      disabled={isMagicLinkLoading}
                      className="flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isMagicLinkLoading ? 'Wird gesendet...' : 'Magic Link per Email senden'}
                    </button>
                  </div>

                  {showMagicLinkSuccess && (
                    <div className="mt-4 rounded-md bg-green-50 p-4">
                      <div className="text-sm text-green-700">
                        Magic Link wurde gesendet! Bitte überprüfen Sie Ihre Emails.
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="mt-6 text-center">
                <span className="w-full text-center text-sm text-black-600">
                  Kein Bock mehr auf Papierkram? <br />
                </span>
                <button
                  onClick={() => setShowSignUp(true)}
                  className="w-full text-center text-sm text-blue-600 hover:text-blue-500"
                >
                  Jetzt kostenlos Account anlegen!
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
