import { Client, Databases, Storage } from 'appwrite';

if (!process.env.REACT_APP_APPWRITE_PROJECT_ID) {
  throw new Error('Appwrite project ID not found in environment variables');
}

export const client = new Client()
  .setEndpoint('https://cloud.appwrite.io/v1')
  .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

export const databases = new Databases(client);
export const storage = new Storage(client);
