// src/pages/ResetPassword.tsx
import { useState, FormEvent } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { createPasswordRecovery, updatePassword, error } = useAuth();

  const userId = searchParams.get('userId');
  const secret = searchParams.get('secret');

  const handleRequestReset = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createPasswordRecovery(email);
      setSuccess(true);
    } catch (error) {
      console.error('Reset request error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordUpdate = async (e: FormEvent) => {
    e.preventDefault();
    if (!userId || !secret) return;

    setIsLoading(true);
    try {
      await updatePassword(userId, secret, password);
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Password update error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/logo.png" alt="Bike Check" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-blue-700">
          Passwort zurücksetzen
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-sm sm:rounded-lg sm:px-10 border border-gray-200">
          {userId && secret ? (
            // Password update form
            <form className="space-y-6" onSubmit={handlePasswordUpdate}>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Neues Passwort
                </label>
                <input
                  type="password"
                  id="password"
                  required
                  className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && <div className="text-red-600 text-sm">Mindestens 8 Zeichen, bitte.</div>}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {isLoading ? 'Wird gespeichert...' : 'Passwort ändern'}
              </button>
            </form>
          ) : success ? (
            // Success message after requesting reset
            <div className="text-center">
              <div className="text-green-600 mb-4">
                Eine Email mit weiteren Anweisungen wurde an {email} versendet.
              </div>
              <p className="text-gray-600 text-sm">
                Bitte überprüfe deinen Email-Eingang und folgen den Anweisungen zum Zurücksetzen des Passworts.
              </p>
            </div>
          ) : (
            // Initial reset request form
            <form className="space-y-6" onSubmit={handleRequestReset}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email Adresse
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {error && <div className="text-red-600 text-sm">{error}</div>}
              <button
                type="submit"
                disabled={isLoading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {isLoading ? 'Wird gesendet...' : 'Passwort zurücksetzen'}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
